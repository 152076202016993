import { isInGracePeriod } from './domainUtils';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { AnyAction, bindActionCreators, CombinedState } from 'redux';
import { configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { CreateControllerFn, Experiments as EditorFlowExperiments } from '@wix/yoshi-flow-editor';

import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { ThunkExtra, ThunkMiddlewareOptions } from '../../types/thunk-extra';
import rootReducer, {
  setLanguage,
  setRegionalSettings,
  setUser,
  fetchAllSubscriptions,
  openDetails,
  closeDetails,
  demoSubscriptions,
  openCancelConfirmModal,
  closeCancelConfirmModal,
  confirmCancel,
  openUpmModal,
  closeUpmModal,
  setIdentityParams,
  setInstance,
  submitUpm,
  onSuccessfulPayNow,
  closeToast,
  setUpmFlowStatus,
  setPayNowFlowStatus,
  openPayNowModal,
  closePayNowModal,
  ToastState,
  onPaymentPendingPayNow,
} from './state';
import { TabState } from '../../types/settings';
import { PAID_PLANS_APP_DEF_ID, PayNowFlowStatus, STORES_APP_DEF_ID, UpmFlowStatus } from './constants';
import { PricingPlanBenefitsServer } from '@wix/ambassador-pricing-plan-benefits-server/http';
import { WixEcommerceSubscriptions } from '@wix/ambassador-wix-ecommerce-subscriptions/http';
import { mySubscriptionsActionItemClick, mySubscriptionsOpened } from '@wix/bi-logger-subscriptions-bm/v2';
import { apiHeaders } from '../../services/headers';
import { IActionCreators } from '../../types/internal-types';
import { ThunkMiddleware } from 'redux-thunk';

interface IEvents {
  tabState: TabState;
}

const createController: CreateControllerFn = async ({ flowAPI }) => {
  const { experiments, controllerConfig } = flowAPI;
  const { setProps, wixCodeApi, appParams } = controllerConfig;
  const { isSSR, isEditor } = flowAPI.environment;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const baseUrl = isSSR ? 'https://www.wix.com' : '';
  const settingsEventsHandler = createEventHandler<IEvents>(publicData);
  const biLogger = flowAPI.bi!;
  const appInstanceId = appParams.instanceId || '00000000-0000-0000-0000-000000000000'; // zero guid is only for tests
  const { instance, appDefinitionId } = appParams;
  const headers = apiHeaders({ Authorization: instance });
  const sessionId = controllerConfig.platformAPIs.bi?.viewerSessionId;

  biLogger.updateDefaults({
    appInstanceId,
    origin: wixCodeApi.window.viewMode,
    sessionId,
  });

  return {
    async pageReady() {
      settingsEventsHandler.on('tabState', (tabState: TabState) => {
        setProps({ tabState });
      });

      settingsEventsHandler.onReset(() => {
        setProps({ tabState: TabState.Default });
      });

      const navigateToStores = ({ emptyState = false } = {}) => {
        biLogger.report(mySubscriptionsActionItemClick({ action: 'view_products', emptyState }));
        wixCodeApi.location.navigateTo?.({ pageId: 'shop' });
      };

      const getMetaSiteId = () => {
        return controllerConfig.platformAPIs.bi?.metaSiteId;
      };

      const getSiteOwnerId = () => {
        return controllerConfig.platformAPIs.bi?.ownerId;
      };
      const resolveLanguage = () => {
        const { translations } = flowAPI;
        const { i18n } = translations;
        const { language } = i18n;
        return language;
      };

      wixCodeApi.site.onInstanceChanged(({ instance: newInstance }) => {
        headers.Authorization = newInstance;
        store.dispatch(setInstance({ instance: newInstance }));
      }, appDefinitionId);

      const navigateToPricingPlans =
        () =>
        async ({ emptyState = false } = {}) => {
          biLogger.report(mySubscriptionsActionItemClick({ action: 'view_plans', emptyState }));
          const structure = await wixCodeApi.site.getSiteStructure({ includePageId: true });
          const pricingPlansPage = structure.pages.filter(
            ({ applicationId }: any) => applicationId === PAID_PLANS_APP_DEF_ID,
          )?.[0];
          if (pricingPlansPage) {
            wixCodeApi.location.navigateTo?.({ pageId: pricingPlansPage.id });
          }
        };

      // This is a hacky way to know if an app is installed. Look for something better.
      const ppInstalled = wixCodeApi.site.getAppToken ? !!wixCodeApi.site.getAppToken(PAID_PLANS_APP_DEF_ID) : false;
      const storesInstalled = wixCodeApi.site.getAppToken ? !!wixCodeApi.site.getAppToken(STORES_APP_DEF_ID) : false;

      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          ...getDefaultMiddleware<any, ThunkMiddlewareOptions>({
            serializableCheck: {
              isSerializable: () => true,
            },
            thunk: {
              extraArgument: {
                baseUrl,
                biLogger,
                fedops: flowAPI.fedops,
                httpClient: flowAPI.httpClient,
                experiments: flowAPI.experiments,
                translations: flowAPI.translations,
                errorMonitor: flowAPI.errorMonitor,
                ecomSubscriptionsService: WixEcommerceSubscriptions(
                  `${baseUrl}/_api/subscriptions-server`,
                ).SubscriptionsApi(),
                memberBenefitsService: PricingPlanBenefitsServer(
                  `${baseUrl}/_api/pricing-plan-benefits`,
                ).MemberBenefits(),
              },
            },
          }),
        ],
      });

      store.subscribe(() => {
        setProps({ state: store.getState() });
      });

      const actionCreators: IActionCreators = {
        fetchAllSubscriptions,
        openDetails,
        closeDetails,
        navigateToStores,
        openCancelConfirmModal,
        closeCancelConfirmModal,
        confirmCancel,
        navigateToPricingPlans,
        closeUpmModal,
        openUpmModal,
        closePayNowModal,
        openPayNowModal,
        submitUpm,
        onSuccessfulPayNow,
        onPaymentPendingPayNow,
        closeToast,
        setUpmFlowStatus,
        setPayNowFlowStatus,
      };

      setProps({
        methods: bindActionCreators<any, any>(actionCreators, store.dispatch),
        state: store.getState(),
        tabState: TabState.Default,
        ppInstalled,
        storesInstalled,
        fitToContentHeight: true,
      });
      store.dispatch(setLanguage(resolveLanguage()));
      store.dispatch(setRegionalSettings(wixCodeApi.site.regionalSettings || wixCodeApi.site.language));
      store.dispatch(
        setUser({
          id: wixCodeApi.user.currentUser.id,
          instance: wixCodeApi.user.currentUser.instance || appParams.instance,
          loggedIn: wixCodeApi.user.currentUser.loggedIn,
        }),
      );
      //* * dont delete until petri support correctly filter by language  */
      // const isEnglishOrLocal = resolveLanguage() === 'en' || appInstanceId === 'test-instance-id';
      // store.dispatch(
      //   setExperiments({
      //     ...experiments.all(),
      // [Experiments.ENABLE_PAY_NOW]: experiments.enabled(Experiments.ENABLE_PAY_NOW) && isEnglishOrLocal, // workaround to open feature english only in editor flow
      //   }),
      // );
      //* * end of saved block  */
      store.dispatch(
        setIdentityParams({
          appInstanceId,
          msid: getMetaSiteId(),
          instance,
          siteOwnerId: getSiteOwnerId(),
          appDefinitionId, // appdefid of subscriptions-tpa
          sessionId,
          visitorId: controllerConfig.platformAPIs.bi?.visitorId,
        }),
      );
      if (isEditor) {
        return store.dispatch<any>(demoSubscriptions());
      }

      wixCodeApi.user.onLogin(() => {
        store.dispatch(
          setUser({
            id: wixCodeApi.user.currentUser.id,
            instance: wixCodeApi.user.currentUser.instance || appParams.instance,
            loggedIn: wixCodeApi.user.currentUser.loggedIn,
          }),
        );
        store.dispatch<any>(fetchAllSubscriptions());
      });

      return store
        .dispatch<any>(fetchAllSubscriptions())
        .then(async (action: any) => {
          const totalSubscriptions = action?.payload ? (action?.payload as any[])?.length : 0;
          if (totalSubscriptions === 1) {
            await store.dispatch<any>(openDetails(action?.payload[0].id));
          }
          await openDetailsOfGracePeriodSubscriptions(experiments, action, store);
          // @todo add error reporting to catch why no action
          biLogger.report(
            mySubscriptionsOpened({
              totalSubscriptions,
              referralInfo: 'null',
              tabName: 'null',
            }),
          );
        })
        .catch((e: Error) => {
          biLogger.report(
            mySubscriptionsOpened({
              totalSubscriptions: 0,
              referralInfo: 'null',
              tabName: 'null',
            }),
          );
          flowAPI.reportError(e);
        });
    },
    updateConfig($w, config) {
      const updatedPublicData = config.publicData.COMPONENT || {};
      settingsEventsHandler.notify(updatedPublicData);
    },
  };
};

export default createController;
async function openDetailsOfGracePeriodSubscriptions(
  experiments: EditorFlowExperiments,
  action: any,
  store: EnhancedStore<
    CombinedState<{
      cancelConfirmModal: { subscriptionId: string | null; isOpen: boolean };
      language: string;
      regionalSettings: string;
      accordion: any[];
      subscriptions: { entities: any; loading: string };
      details: { entities: {}; loading: any[] };
      user: any;
      upmModal: { subscriptionId: string | null; isOpen: boolean; upmFlowStatus: UpmFlowStatus };
      payNowModal: {
        subscriptionId: string | null;
        isOpen: boolean;
        paymentOrderId?: string | undefined;
        payNowFlowStatus: PayNowFlowStatus;
      };
      IdentityParams: {
        appInstanceId: string;
        msid: string;
        instance: string;
        siteOwnerId: string;
        appDefinitionId: string;
        sessionId: string;
      };
      toast: ToastState;
    }>,
    AnyAction,
    ThunkMiddleware<any, AnyAction, ThunkExtra>[]
  >,
) {
  if (action?.payload) {
    await Promise.all(
      action.payload.map((subscription: Subscription) => {
        if (isInGracePeriod(subscription)) {
          return subscription?.id && store.dispatch<any>(openDetails(subscription.id));
        }
      }),
    );
  }
}
